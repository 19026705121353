define("@videoly/ember-components/components/v-banner-container", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/service", "@ember/object"], function (_exports, _component, _templateFactory, _component2, _service, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div
    class="v-banner-container"
    {{did-insert this.initContainer}}
    ...attributes
  >
    {{yield (hash Banner=(component this.bannerComponent
      hideBanner=this.hideBanner)
    messages=this.bannerController.messages)}}
  </div>
  
  */
  {
    "id": "f0fNgTxg",
    "block": "[[[11,0],[24,0,\"v-banner-container\"],[17,1],[4,[38,0],[[30,0,[\"initContainer\"]]],null],[12],[1,\"\\n  \"],[18,2,[[28,[37,2],null,[[\"Banner\",\"messages\"],[[50,[30,0,[\"bannerComponent\"]],0,null,[[\"hideBanner\"],[[30,0,[\"hideBanner\"]]]]],[30,0,[\"bannerController\",\"messages\"]]]]]]],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"&attrs\",\"&default\"],false,[\"did-insert\",\"yield\",\"hash\",\"component\"]]",
    "moduleName": "@videoly/ember-components/components/v-banner-container.hbs",
    "isStrictMode": false
  });
  let VBannerContainerComponent = _exports.default = (_class = class VBannerContainerComponent extends _component2.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "bannerComponent", 'v-banner-container/banner');
      _initializerDefineProperty(this, "bannerController", _descriptor, this);
    }
    initContainer(el) {
      this.bannerController.initController({
        el,
        duration: this.args.duration
      });
    }
    hideBanner(message) {
      this.bannerController.remove(message);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "bannerController", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "initContainer", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "initContainer"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "hideBanner", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "hideBanner"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, VBannerContainerComponent);
});