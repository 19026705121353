define("@videoly/ember-components/components/v-datepicker", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object", "@glimmer/tracking"], function (_exports, _component, _templateFactory, _component2, _object, _tracking) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="v-datepicker" ...attributes>
    {{yield (hash
      Calendar=(component this.calendarComponent
        center=this.center
        registerCalendarUse=this.registerCalendarUse
        selectedDate=this.selectedDate
        handleCenterChange=this.handleCenterChange
        handleSelect=this.handleCalendarSelect)
  
      Time=(component this.timeComponent
        registerTimeUse=this.registerTimeUse
        selectedTime=this.selectedTime
        handleChange=this.handleTimeChange
        isVisible=this.isTimeVisible)
  
      Buttons=(component this.buttonsComponent
        registerButtonsUse=this.registerButtonsUse
        isTimeUsed=this.isTimeUsed
        isTimeVisible=this.isTimeVisible
        saveIsDisabled=this.saveIsDisabled
        toggleChange=this.handleIsTimeVisibleToggle
        handleButtonCancelClick=@performCancel
        handleButtonSaveClick=this.handleButtonSaveClick)
  
      QuickMenu=(component this.quickSelectMenu
        handleCalendarSelect=this.handleCalendarSelect
        handleCenterChange=this.handleCenterChange)
  
      actions=(hash
        handleCalendarSelect=this.handleCalendarSelect
        handleIsTimeVisibleToggle=this.handleIsTimeVisibleToggle
        handleTimeChange=this.handleTimeChange
      )
    )}}
  </div>
  
  */
  {
    "id": "OpvWE7xl",
    "block": "[[[11,0],[24,0,\"v-datepicker\"],[17,1],[12],[1,\"\\n  \"],[18,3,[[28,[37,1],null,[[\"Calendar\",\"Time\",\"Buttons\",\"QuickMenu\",\"actions\"],[[50,[30,0,[\"calendarComponent\"]],0,null,[[\"center\",\"registerCalendarUse\",\"selectedDate\",\"handleCenterChange\",\"handleSelect\"],[[30,0,[\"center\"]],[30,0,[\"registerCalendarUse\"]],[30,0,[\"selectedDate\"]],[30,0,[\"handleCenterChange\"]],[30,0,[\"handleCalendarSelect\"]]]]],[50,[30,0,[\"timeComponent\"]],0,null,[[\"registerTimeUse\",\"selectedTime\",\"handleChange\",\"isVisible\"],[[30,0,[\"registerTimeUse\"]],[30,0,[\"selectedTime\"]],[30,0,[\"handleTimeChange\"]],[30,0,[\"isTimeVisible\"]]]]],[50,[30,0,[\"buttonsComponent\"]],0,null,[[\"registerButtonsUse\",\"isTimeUsed\",\"isTimeVisible\",\"saveIsDisabled\",\"toggleChange\",\"handleButtonCancelClick\",\"handleButtonSaveClick\"],[[30,0,[\"registerButtonsUse\"]],[30,0,[\"isTimeUsed\"]],[30,0,[\"isTimeVisible\"]],[30,0,[\"saveIsDisabled\"]],[30,0,[\"handleIsTimeVisibleToggle\"]],[30,2],[30,0,[\"handleButtonSaveClick\"]]]]],[50,[30,0,[\"quickSelectMenu\"]],0,null,[[\"handleCalendarSelect\",\"handleCenterChange\"],[[30,0,[\"handleCalendarSelect\"]],[30,0,[\"handleCenterChange\"]]]]],[28,[37,1],null,[[\"handleCalendarSelect\",\"handleIsTimeVisibleToggle\",\"handleTimeChange\"],[[30,0,[\"handleCalendarSelect\"]],[30,0,[\"handleIsTimeVisibleToggle\"]],[30,0,[\"handleTimeChange\"]]]]]]]]]],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"&attrs\",\"@performCancel\",\"&default\"],false,[\"yield\",\"hash\",\"component\"]]",
    "moduleName": "@videoly/ember-components/components/v-datepicker.hbs",
    "isStrictMode": false
  });
  const DEFAULT_SELECTED_TIME = '00:00';
  const TIME_DEFAULT = {
    start: DEFAULT_SELECTED_TIME,
    end: DEFAULT_SELECTED_TIME
  };
  let VDatepickerComponent = _exports.default = (_class = class VDatepickerComponent extends _component2.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "calendarComponent", 'v-datepicker/calendar');
      _defineProperty(this, "timeComponent", 'v-datepicker/time');
      _defineProperty(this, "buttonsComponent", 'v-datepicker/buttons');
      _defineProperty(this, "quickSelectMenu", 'v-datepicker/quick-select-menu');
      _initializerDefineProperty(this, "areButtonsUsed", _descriptor, this);
      _initializerDefineProperty(this, "isCalendarUsed", _descriptor2, this);
      _initializerDefineProperty(this, "isTimeUsed", _descriptor3, this);
      _initializerDefineProperty(this, "selectedTime", _descriptor4, this);
      _initializerDefineProperty(this, "selectedDate", _descriptor5, this);
      _initializerDefineProperty(this, "center", _descriptor6, this);
      _initializerDefineProperty(this, "isTimeVisibleToggle", _descriptor7, this);
      this.selectedDate = this.args.selectedDate || null;
      this.center = this.args.center || null;
    }
    get isTimeVisible() {
      if (!this.areButtonsUsed) {
        return true;
      }
      return this.isTimeVisibleToggle && this.areButtonsUsed;
    }
    get saveIsDisabled() {
      return !this.selectedDate;
    }
    registerButtonsUse() {
      this.areButtonsUsed = true;
    }
    registerCalendarUse() {
      this.isCalendarUsed = true;
    }
    registerTimeUse() {
      this.isTimeUsed = true;
    }
    handleCenterChange(center) {
      this.center = center;
    }
    handleCalendarSelect(_ref2) {
      let {
        date
      } = _ref2;
      const isDateEqualToSelected = this.selectedDate && Number(this.selectedDate) === Number(date);
      this.selectedDate = isDateEqualToSelected ? null : date;
      if (!this.areButtonsUsed) {
        return this.handleButtonSaveClick();
      }
    }
    handleTimeChange(newTime) {
      this.selectedTime = newTime;
      if (!this.areButtonsUsed) {
        return this.handleButtonSaveClick();
      }
    }
    handleIsTimeVisibleToggle() {
      if (this.isTimeVisibleToggle) {
        this.isTimeVisibleToggle = false;
        this.selectedTime = TIME_DEFAULT;
      } else {
        this.isTimeVisibleToggle = true;
      }
    }
    handleButtonSaveClick() {
      return this.args.performSave({
        selectedTime: this.selectedTime,
        selectedDate: this.selectedDate
      });
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "areButtonsUsed", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "isCalendarUsed", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "isTimeUsed", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "selectedTime", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return TIME_DEFAULT;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "selectedDate", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "center", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "isTimeVisibleToggle", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "registerButtonsUse", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "registerButtonsUse"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "registerCalendarUse", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "registerCalendarUse"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "registerTimeUse", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "registerTimeUse"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleCenterChange", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleCenterChange"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleCalendarSelect", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleCalendarSelect"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleTimeChange", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleTimeChange"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleIsTimeVisibleToggle", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleIsTimeVisibleToggle"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleButtonSaveClick", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleButtonSaveClick"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, VDatepickerComponent);
});