define("@videoly/ember-components/components/v-submit-button", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object", "@glimmer/tracking"], function (_exports, _component, _templateFactory, _component2, _object, _tracking) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="v-submit-button">
    <v-button
      ...attributes
      {{on "buttonClick" this.onClick}}
    />
    <button
      aria-label="submit-form"
      class="v-submit-button__hidden"
      type="submit"
      {{did-insert this.setButtonElement}}
    />
  </div>
  
  */
  {
    "id": "H9hvxKf/",
    "block": "[[[10,0],[14,0,\"v-submit-button\"],[12],[1,\"\\n  \"],[11,\"v-button\"],[17,1],[4,[38,0],[\"buttonClick\",[30,0,[\"onClick\"]]],null],[12],[13],[1,\"\\n  \"],[11,\"button\"],[24,\"aria-label\",\"submit-form\"],[24,0,\"v-submit-button__hidden\"],[24,4,\"submit\"],[4,[38,1],[[30,0,[\"setButtonElement\"]]],null],[12],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"&attrs\"],false,[\"on\",\"did-insert\"]]",
    "moduleName": "@videoly/ember-components/components/v-submit-button.hbs",
    "isStrictMode": false
  });
  let VSubmitButton = _exports.default = (_class = class VSubmitButton extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "buttonElement", _descriptor, this);
    }
    setButtonElement(el) {
      this.buttonElement = el;
    }
    onClick() {
      this.buttonElement.click();
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "buttonElement", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "setButtonElement", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "setButtonElement"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onClick", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onClick"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, VSubmitButton);
});