define("@videoly/ember-components/components/v-contenteditable", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object", "@glimmer/tracking", "@videoly/ember-components/utils/class-list"], function (_exports, _component, _templateFactory, _component2, _object, _tracking, _classList) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div
    class={{this.classes}}
    contenteditable={{not this.isDisabled}}
    placeholder={{@placeholder}}
    {{did-insert this.onInsert}}
    {{did-update this.onUpdate @value}}
    {{on 'keydown' this.onKeyDown}}
    {{on 'paste' this.onPaste}}
    {{on 'input' this.onInput}}
    {{on 'blur' this.onBlur}}
    ...attributes
    data-test-contenteditable
  />
  
  */
  {
    "id": "D9zfzulT",
    "block": "[[[11,0],[16,0,[30,0,[\"classes\"]]],[16,\"contenteditable\",[28,[37,0],[[30,0,[\"isDisabled\"]]],null]],[16,\"placeholder\",[30,1]],[17,2],[4,[38,1],[[30,0,[\"onInsert\"]]],null],[4,[38,2],[[30,0,[\"onUpdate\"]],[30,3]],null],[4,[38,3],[\"keydown\",[30,0,[\"onKeyDown\"]]],null],[4,[38,3],[\"paste\",[30,0,[\"onPaste\"]]],null],[4,[38,3],[\"input\",[30,0,[\"onInput\"]]],null],[4,[38,3],[\"blur\",[30,0,[\"onBlur\"]]],null],[12],[13],[1,\"\\n\"]],[\"@placeholder\",\"&attrs\",\"@value\"],false,[\"not\",\"did-insert\",\"did-update\",\"on\"]]",
    "moduleName": "@videoly/ember-components/components/v-contenteditable.hbs",
    "isStrictMode": false
  });
  const alwaysAllowedKeys = [8,
  // backspace
  9,
  // tab
  12,
  // clear
  16,
  // shift
  17,
  // control
  18,
  // option
  20,
  // caps
  27,
  // escape
  35,
  // end
  36,
  // home
  37,
  // left arrow
  38,
  // up arrow
  39,
  // right arrow
  40,
  // down arrow
  46,
  // delete
  91,
  // meta left
  93 // meta right
  ];
  let ContentEditableComponent = _exports.default = (_class = class ContentEditableComponent extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "value", _descriptor, this);
      _defineProperty(this, "originalValue", '');
      _defineProperty(this, "element", null);
      _defineProperty(this, "isInternalBlurEvent", false);
    }
    get isDisabled() {
      var _this$args$disabled;
      return (_this$args$disabled = this.args.disabled) !== null && _this$args$disabled !== void 0 ? _this$args$disabled : false;
    }
    get classes() {
      return (0, _classList.default)({
        'v-contenteditable': true,
        'v-contenteditable--clear-on-focus': this.args.clearPlaceholderOnFocus === true
      });
    }
    onInsert(element) {
      this.element = element;
      this.onUpdate();
      if (this.args.autofocus) {
        this.element.focus();
      }
    }
    onUpdate() {
      if (this.value === this.args.value) {
        return;
      }
      this.value = this.args.value;
      this.updateOriginalValue();
      this.updateValue();
    }
    onKeyDown(event) {
      const isEnter = event.keyCode === 13;
      const isEscape = event.keyCode === 27;
      if (isEnter && !this.args.allowNewLines) {
        event.preventDefault();
        if (!this.onEnterKeyDown()) {
          return;
        }
      }
      if (isEscape && !this.onEscapeKeyDown()) {
        event.preventDefault();
        return;
      }
      if (this.isMaxLengthExceededAfterKeyDown(event)) {
        this.triggerOnLengthExceeded(this.value.length + 1);
        event.preventDefault();
      }
    }
    onInput() {
      this.value = this.element.innerText;
      if (this.args.onChange) {
        this.args.onChange(this.value);
      }
    }
    onBlur() {
      this.resetValue();
      this.updateOriginalValue();
      if (this.args.onBlur) {
        this.args.onBlur(this.value, this.isInternalBlurEvent);
      }
    }
    onPaste(event) {
      event.preventDefault();
      const text = this.getPlainTextFromClipboard(event);
      if (this.isMaxLengthExceededForPastedText(text.length)) {
        this.triggerOnLengthExceeded(text.length);
        return false;
      }
      this.crossSupportInsertText(text);
    }
    updateValue() {
      if (this.value) {
        this.element.innerText = this.value;
      } else {
        this.element.innerText = '';
      }
    }
    updateOriginalValue() {
      this.originalValue = this.value;
    }
    blur() {
      this.isInternalBlurEvent = true;
      this.element.blur();
      this.isInternalBlurEvent = false;
      window.getSelection().removeAllRanges();
    }
    onEnterKeyDown() {
      this.resetValue();
      if (this.args.onEnter) {
        this.args.onEnter(this.value);
      }
      if (this.args.blurOnEnter) {
        this.updateOriginalValue();
        this.blur();
        return false;
      }
      return true;
    }
    onEscapeKeyDown() {
      if (this.args.allowResetValue) {
        this.value = this.originalValue;
        this.updateValue();
      }
      if (this.args.onEscape) {
        this.args.onEscape(this.value);
      }
      if (this.args.blurOnEscape) {
        this.updateOriginalValue();
        this.blur();
        return false;
      }
      return true;
    }
    resetValue() {
      if (this.args.allowResetValue && this.value === '') {
        this.value = this.originalValue;
        this.updateValue();
      }
    }

    /**
     * @param {KeyboardEvent} event
     * @returns {any|boolean}
     */
    isMaxLengthExceededAfterKeyDown(event) {
      var _this$value;
      return this.args.maxContentLength && this.args.maxContentLength <= ((_this$value = this.value) === null || _this$value === void 0 ? void 0 : _this$value.length) && !alwaysAllowedKeys.includes(event.keyCode) && !event.ctrlKey && !event.metaKey;
    }
    isMaxLengthExceededForPastedText(pastedTextLength) {
      if (!this.args.maxContentLength) {
        return false;
      }

      // a selection will be replaced. substract the length of the selection from the total length
      const selectionLength = this.getSelectionLength();
      const afterPasteLength = pastedTextLength + this.value.length - selectionLength;
      return afterPasteLength > this.args.maxContentLength;
    }
    triggerOnLengthExceeded(contentLength) {
      if (this.args.onLengthExceeded) {
        this.args.onLengthExceeded(contentLength);
      }
    }
    getPlainTextFromClipboard(event) {
      // replace any html formatted text with its plain text equivalent
      let text = '';
      if (event.clipboardData) {
        text = event.clipboardData.getData('text/plain');
      } else if (window.clipboardData) {
        text = window.clipboardData.getData('Text');
      }
      if (!this.args.allowNewLines) {
        text = text.replace(/\n/g);
      }
      return text;
    }
    getSelectionLength() {
      const selection = window.getSelection();
      if (selection && selection.rangeCount > 0) {
        const range = selection.getRangeAt(0);
        return range.endOffset - range.startOffset;
      }
      return 0;
    }
    crossSupportInsertText(text) {
      if (document.queryCommandSupported('insertText')) {
        document.execCommand('insertText', false, text);
      } else {
        const range = document.getSelection().getRangeAt(0);
        range.deleteContents();
        const textNode = document.createTextNode(text);
        range.insertNode(textNode);
        range.selectNodeContents(textNode);
        range.collapse(false);
        const selection = document.getSelection();
        selection.removeAllRanges();
        selection.addRange(range);
      }
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "value", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _applyDecoratedDescriptor(_class.prototype, "onInsert", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onInsert"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onUpdate", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onUpdate"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onKeyDown", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onKeyDown"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onInput", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onInput"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onBlur", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onBlur"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onPaste", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onPaste"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ContentEditableComponent);
});