define("@videoly/ember-components/components/v-pagination", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@videoly/ember-components/components/v-pagination/util", "@videoly/ember-components/components/v-pagination/lib/page-items", "@ember/object", "@ember/object/computed"], function (_exports, _component, _templateFactory, _component2, _util, _pageItems, _object, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <ul class="v-pagination">
    {{#if this.canStepBackward}}
      <li
        class="v-pagination__item v-pagination__arrow v-pagination__arrow--active"
        {{on "click" (fn this.pageClicked this.currentPage -1)}}
        role="button"
        data-test-action-previous-page
      >
        <v-icon name="Lined_Arrow_Left"/>
      </li>
    {{else}}
      <li class="v-pagination__item v-pagination__arrow" data-test-action-previous-page>
        <v-icon name="Lined_Arrow_Left"/>
      </li>
    {{/if}}
  
    {{#each this.pageItems as |item|}}
      {{#if item.dots}}
        <li class="v-pagination__item v-pagination__item--dots" data-test-dots>...</li>
      {{/if}}
  
      <li
        class="v-pagination__item {{if item.current "v-pagination__item--active"}}"
        {{on "click" (fn this.pageClicked item.page 0)}}
        role="button"
        data-test-page
      >
        {{item.page}}
      </li>
    {{/each}}
  
    {{#if this.canStepForward}}
      <li
        class="v-pagination__item v-pagination__arrow v-pagination__arrow--active"
        {{on "click" (fn this.pageClicked this.currentPage 1)}}
        role="button"
        data-test-action-next-page
      >
        <v-icon name="Lined_Arrow_Right"/>
      </li>
    {{else}}
      <li class="v-pagination__item v-pagination__arrow" data-test-action-next-page>
        <v-icon name="Lined_Arrow_Right"/>
      </li>
    {{/if}}
  </ul>
  
  */
  {
    "id": "Q0JjnBqr",
    "block": "[[[10,\"ul\"],[14,0,\"v-pagination\"],[12],[1,\"\\n\"],[41,[30,0,[\"canStepBackward\"]],[[[1,\"    \"],[11,\"li\"],[24,0,\"v-pagination__item v-pagination__arrow v-pagination__arrow--active\"],[24,\"role\",\"button\"],[4,[38,1],[\"click\",[28,[37,2],[[30,0,[\"pageClicked\"]],[30,0,[\"currentPage\"]],-1],null]],null],[12],[1,\"\\n      \"],[10,\"v-icon\"],[14,3,\"Lined_Arrow_Left\"],[12],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],[[[1,\"    \"],[10,\"li\"],[14,0,\"v-pagination__item v-pagination__arrow\"],[12],[1,\"\\n      \"],[10,\"v-icon\"],[14,3,\"Lined_Arrow_Left\"],[12],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]]],[1,\"\\n\"],[42,[28,[37,4],[[28,[37,4],[[30,0,[\"pageItems\"]]],null]],null],null,[[[41,[30,1,[\"dots\"]],[[[1,\"      \"],[10,\"li\"],[14,0,\"v-pagination__item v-pagination__item--dots\"],[12],[1,\"...\"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n    \"],[11,\"li\"],[16,0,[29,[\"v-pagination__item \",[52,[30,1,[\"current\"]],\"v-pagination__item--active\"]]]],[24,\"role\",\"button\"],[4,[38,1],[\"click\",[28,[37,2],[[30,0,[\"pageClicked\"]],[30,1,[\"page\"]],0],null]],null],[12],[1,\"\\n      \"],[1,[30,1,[\"page\"]]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[1]],null],[1,\"\\n\"],[41,[30,0,[\"canStepForward\"]],[[[1,\"    \"],[11,\"li\"],[24,0,\"v-pagination__item v-pagination__arrow v-pagination__arrow--active\"],[24,\"role\",\"button\"],[4,[38,1],[\"click\",[28,[37,2],[[30,0,[\"pageClicked\"]],[30,0,[\"currentPage\"]],1],null]],null],[12],[1,\"\\n      \"],[10,\"v-icon\"],[14,3,\"Lined_Arrow_Right\"],[12],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],[[[1,\"    \"],[10,\"li\"],[14,0,\"v-pagination__item v-pagination__arrow\"],[12],[1,\"\\n      \"],[10,\"v-icon\"],[14,3,\"Lined_Arrow_Right\"],[12],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]]],[13],[1,\"\\n\"]],[\"item\"],false,[\"if\",\"on\",\"fn\",\"each\",\"-track-array\"]]",
    "moduleName": "@videoly/ember-components/components/v-pagination.hbs",
    "isStrictMode": false
  });
  let VPaginationComponent = _exports.default = (_class = class VPaginationComponent extends _component2.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "truncatePages", true);
    }
    get content() {
      return this.args.content;
    }
    get currentPage() {
      return this.content.page;
    }
    get totalPages() {
      return this.content.totalPages;
    }
    get hasPages() {
      return this.totalPages > 1;
    }
    get numPagesToShow() {
      var _this$args$pagesToSho;
      return (_this$args$pagesToSho = this.args.pagesToShow) !== null && _this$args$pagesToSho !== void 0 ? _this$args$pagesToSho : 3;
    }
    validate() {
      if (_util.default.isBlank(this.currentPage)) {
        throw new Error('Pagination error: no currentPage for page-numbers');
      }
      if (_util.default.isBlank(this.totalPages)) {
        throw new Error('Pagination error: no totalPages for page-numbers');
      }
    }
    get pageItemsObj() {
      let result = _pageItems.default.create({
        parent: this
      });
      (0, _object.defineProperty)(result, 'currentPage', (0, _computed.alias)('parent.currentPage'));
      (0, _object.defineProperty)(result, 'totalPages', (0, _computed.alias)('parent.totalPages'));
      (0, _object.defineProperty)(result, 'truncatePages', (0, _computed.alias)('parent.truncatePages'));
      (0, _object.defineProperty)(result, 'numPagesToShow', (0, _computed.alias)('parent.numPagesToShow'));
      (0, _object.defineProperty)(result, 'showFL', (0, _computed.alias)('parent.args.showFL'));
      return result;
    }
    get pageItems() {
      this.validate();
      return this.pageItemsObj.get('pageItems');
    }
    get canStepForward() {
      const page = Number(this.currentPage);
      const totalPages = Number(this.totalPages);
      return page < totalPages;
    }
    get canStepBackward() {
      const page = Number(this.currentPage);
      return page > 1;
    }
    pageClicked(number, direction) {
      this.args.onClick(+number + direction);
    }
  }, _applyDecoratedDescriptor(_class.prototype, "pageClicked", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "pageClicked"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, VPaginationComponent);
});